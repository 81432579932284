import {h} from 'preact'
import {Link} from 'preact-router/match'

const Header = ({url, token, me}) => (
  <header class="header">
    <h1>Netalyze</h1>
    {token ? (
      <div>
        <nav className="float-left">
          <a class={url.match(/leads/) ? 'active' : ''} href="/leads">
            Leads
          </a>
          <a class={url.match(/offers/) ? 'active' : ''} href="/offers">
            Offers
          </a>
          <a class={url.match(/transfers/) ? 'active' : ''} href="/transfers">
            Transfers
          </a>
          <a class={url.match(/orgs/) ? 'active' : ''} href="/orgs">
            ORGs
          </a>
          <a class={url.match(/(nets$|nets\/)/) ? 'active' : ''} href="/nets">
            Networks
          </a>
          <a class={url.match(/requests/) ? 'active' : ''} href="/requests">
            Requests
          </a>
          <a class={url.match(/invoices/) ? 'active' : ''} href="/invoices">
            Invoices
          </a>
          <a class={url.match(/whois/) ? 'active' : ''} href="/whois">
            Whois
          </a>
          <a class={url.match(/bl/) ? 'active' : ''} href="/bl">
            BL
          </a>
          <a class={url.match(/ta/) ? 'active' : ''} href="/ta">
            TA
          </a>
          <a class={url.match(/rent/) ? 'active' : ''} href="/rent">
            Rent
          </a>
          <a class={url.match(/memo/) ? 'active' : ''} href="/memo">
            Memo
          </a>
        </nav>
        <nav>
          <Link activeClassName="active" href={`/profile/${me.nick}`}>
            {me.nick}
          </Link>
        </nav>
      </div>
    ) : (
      <nav>
        <Link activeClassName="active" href="/">
          Login
        </Link>
      </nav>
    )}
  </header>
)

export default Header
