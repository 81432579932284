import { h } from 'preact'
import { useState } from 'preact/hooks'
import Modal from './modal'

const Notify = () => {
  const [showModal, setShowModal] = useState(() => {
    if (("Notification" in window)) {
      if (Notification.permission === "denied") return false
      return Notification.permission !== "granted"
    }
    return false
  })

  const grantPerm = () => {
    Notification.requestPermission().then(function (permission) {
      // If the user accepts, let's create a notification
      if (permission === "granted") {
        const notification = new Notification("Notification enabled!")
      }
    })
    setShowModal(false)
  }

  return <Modal show={showModal} title="Enable notifications" onClose={() => setShowModal(false)}>
    <label>Please <b>Allow</b> notifications</label>
    <button class="btn btn-success" onClick={grantPerm}>Allow Notifications</button>

  </Modal>
}

export default Notify



