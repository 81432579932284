import { h } from 'preact'
import { Router, route } from 'preact-router'
import { useEffect, useState } from 'preact/hooks'
import { useQuery, useSubscription } from '@apollo/client'
import { currSign, notify } from './utils'

import Q from './q'
import Header from './components/header'

// Code-splitting is automated for routes
import Net from './routes/net'
import Nets from './routes/nets'
import Net6 from './routes/net6'
import Nets6 from './routes/nets6'
import Transfer from './routes/transfer'
import Transfers from './routes/transfers'
import Org from './routes/org'
import Orgs from './routes/orgs'
import Leads from './routes/leads'
import Requests from './routes/requests'
import Invoices from './routes/invoices'
import BL from './routes/bl'
import TA from './routes/ta'
import Rent from './routes/rent'
import Memo from './routes/memo'

import Offers from './routes/offers'
import Profile from './routes/profile'
import Login from './routes/login'
import Admin from './routes/admin'
import AdminUser from './routes/admin-user'
import Stats from './routes/stats'
import Whois from './routes/whois'
import Notify from './components/notify'

const statuses = ['AVAIL_BUY', 'AVAIL_SALE', 'USED', 'NON_ACTUAL', 'ON_HOLD', 'AVAIL_LEASE']

const App = ({ link }) => {
  const [url, setUrl] = useState('/')
  const [token, setToken] = useState(localStorage.getItem('beauth'))

  useEffect(() => {
    document.cookie = 'token=' + token
    return () => {
      document.cookie = ''
    }
  }, [token])

  const { loading, error, data: { me = {} } = {}, refetch } = useQuery(Q.Q_ME)
  useSubscription(Q.S_UPDATE, {
    onSubscriptionData: ({
      subscriptionData: {
        data: { update }
      }
    }) => {
      // console.log('- onSub ->', update)
      if (update.__typename === 'Offer' && update.bids?.length > 0) {
        const lastBid = update.bids[update.bids.length - 1]
        if (lastBid?.bid && lastBid?.bid != update.price) {
          notify(`${currSign[update.curr]} ${lastBid.bid} - ${update.type} ${update.rir} ${update.pref}`, `${lastBid.nick}`)
        }
      }
    }
  })

  const updateUser = user => {
    if (!user?.token) return
    localStorage.setItem('beauth', user.token)
    link.subscriptionClient.close(false, false)
    console.log('link reset => ', link)
    setToken(user.token)
    setTimeout(() => route('/leads', true), 500)
    // refetch()
  }

  const logoutUser = () => {
    // setTimeout(() => window.location.href = '/login', 100)
    localStorage.clear()
    setToken(false)
  }

  const onRoute = ({ url }) => {
    if (url === '/') route('/leads')
    setUrl(url)
  }

  if (!loading && error?.toString()?.match(/access denied|malformed/i)) {
    // console.log('- me ->', me, '[' + error?.toString() + ']', loading)
    logoutUser()
  }

  return (
    <div id="app">
      <Header url={url} token={token} me={me} />
      <Notify />

      {token ? (
        <Router onChange={onRoute}>
          <Leads exact path="/" />
          <Leads path="/leads" />
          <Requests path="/requests" />
          <Invoices path="/invoices" />

          <Offers path="/offers" notify={notify} />

          <Nets path="/nets/:page?" me={me} />

          <Nets path="/my/:page?" me={me} my={true} />
          <Nets path="/buy/:page?" me={me} status="AVAIL_BUY" />
          <Nets path="/sell/:page?" me={me} status="AVAIL_SALE" />
          <Nets path="/lease/:page?" me={me} status="AVAIL_LEASE" />
          <Nets path="/used/:page?" me={me} status="USED" />
          <Nets path="/nonactual/:page?" me={me} status="NON_ACTUAL" />
          <Nets path="/hold/:page?" me={me} status="ON_HOLD" />

          <Net path="/net/:id" statuses={statuses} />
          <Net path="/net/:base/:prefix" statuses={statuses} />

          <Nets6 path="/nets6/:page?" me={me} />
          <Net6 path="/net6/:id" me={me} statuses={statuses} />
          <Net6 path="/net6/:base/:prefix" me={me} statuses={statuses} />

          <Transfers path="/transfers/:page?" />
          <Transfer path="/transfer/:id" />

          <Org path="/org/:org" me={me} />
          <Org path="/org/:org/:org2" me={me} />
          <Orgs path="/orgs/:page?" me={me} />

          <Whois path="/whois/:q?" />
          <Whois path="/whois/:q/:q2" />

          <BL path="/bl" />
          <TA path="/ta" token={token} />
          <Rent path="/rent" />
          <Memo path="/memo" />

          <Profile path="/profile/:user" token={token} me={me} logoutUser={logoutUser} />
          {me?.admin && <Admin path="/admin" me={me} />}
          {me?.admin && <AdminUser path="/admin/:id" me={me} />}
          {me?.admin && <Stats path="/stats" />}
        </Router>
      ) : (
        <Login updateUser={updateUser} token={token} />
      )}
    </div>
  )
}

export default App
